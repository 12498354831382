.fader {
  animation: fade 5s ease-in-out;
  animation-fill-mode: forwards;
  display: none;
  text-align: center;
}

.active {
  display: block;
}

.fade-container {
  position: relative;
  text-align: center;
  margin-top: -80px;
  width: 100%;
}

.first-text {
  display: inline;
}

.parent-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

@keyframes fade {
  0% {
    color: rgba(0, 0, 0, 0);

    transform: translate(0);
  }
  50% {
    color: rgba(255, 255, 255, 1);

    transform: translate(0, 50px);
  }
  100% {
    color: rgba(0, 0, 0, 0);

    transform: translate(0, 50px);
  }
}
