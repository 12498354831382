.blog {
	text-align: left;
	width: 80%;
	margin: 100px auto;
}

.resp-container {
	position: relative;
	padding-bottom: 108%;
	padding-top: 30px;
	height: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.resp-iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}
